import React from "react";
import {NosImplantationsPage} from 'amg-it-theme'; 

let nosImplantationsPageData ={
  header: {
    background: "societe",
    title: "société",
    path: "ACCUEIL / SOCIÉTÉ / NOS IMPLANTATIONS"
  },
  content:{
    category: "nos implantations",
    // title: "Une entreprise de services du numérique (ESN)",
    // subtitle: "Créée en 2014, société à taille humaine, AMG-IT se distingue par sa proximité avec ses clients mais aussi ses collaborateurs par une approche inédite de la gestion des ressources humaines."
  }
}

const LocalPage = () => (
  <NosImplantationsPage {...nosImplantationsPageData}/>
);

export default LocalPage;